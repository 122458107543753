import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { useMixpanel  } from 'gatsby-plugin-mixpanel'
import {Link} from "gatsby"



const CustomComponent = ({ id, type, linkType }) => {
  if (type === 'inline') {
    return (
    <span style={{color: 'red'}}>
    [{linkType} {id}]
    </span>
    )
  }

  if (type === 'block') {
    return (
    <div style={{color: 'red'}}>
    [{linkType} {id}]
    </div>
    )
  }

};

const locale='en-US'

const EmbeddedEntry = ({ title, shortDescription, slug, cover }) => {
  return (
    <div className="embedded-entry-box">
      <article className="article-recent zero-margin">
        <div className="article-recent-secondary order-last">
          <div >
          <img
            src={cover[locale].fields.file[locale].url}
            alt="simple white desk on a white wall with 
            a plant on the far right side"
            className="embedded-entry-image align"
          /></div>
        </div>

        <div className="article-recent-main">
          <Link  to={'/'+slug[locale]} className="article-read-more ">
            <h2 className="widget-title">{title[locale]} </h2>
          </Link>
        </div>

      </article>
    </div>
  )
}

const EmbeddedExternalEntry = ({ uri, text, data}) => {
  // this mus be reviewed
  const props = {
    title: "",
    description: "",
    image: "https://via.placeholder.com/180x120"
  }
  if (data.description && data.description.length >= 170) {
    data.description = data.description.substr(0,169) + '...'
  }
    return (
      <div className="embedded-entry-box">
        <article className="article-recent zero-margin">
          <div className="article-recent-secondary order-last">
            <div >
            <img
              src={data.image ? data.image : props.image}
              alt="simple white desk on a white wall with 
              a plant on the far right side"
              className="embedded-entry-image align"
            /></div>
          </div>

          <div className="article-recent-main">
            <a href={uri} target="_blank" rel="noreferrer" className="article-read-more ">
              <h2 className="widget-title">{data.title ? data.title : text} </h2>
              <span className="widget-description">{data.description ? data.description : text}</span>
            </a>
          </div>

        </article>
      </div>
    )
}

const EmbeddedAsset = ({ title, description, file }) => {
  return (
    <figure>
      <img
              src={file[locale].url}
              alt={title}
              className="embedded-entry-image align"
      />
      <figcaption>{description ? description[locale]:''}</figcaption>
    </figure>  
  )

}

const MarksCode = ({text}) => {
  return (
    <pre>
      <code>
        {text}
      </code>
    </pre>
  )
}

const MarksQuote = ({text}) => {
  return (
    <div className="blockquote">
        {text}
    </div>
  )
}


const InlineLink = ({uri, text}) => {
  const mixpanel = useMixpanel()
  const clickTracer = (title) => {
    mixpanel.track("LINK: "+title);
  }
  return (
    <a href={uri} target="_blank" rel="noopener noreferrer" onClick={() => clickTracer(text)}>{text}</a>
  )
}

const InlineVimeo = ({uri, text}) => {
    var src = ''

    let arr = uri.split('/')
    src = arr[arr.length-1]

   return (
    <p class="iframe-container">
      <iframe title={text} src={process.env.REACT_APP_VIMEO_EMBED_PATH + src} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </p>
  )
}

const InlineYoutube = ({uri, text}) => {
  var src = ''
  if (uri.includes("youtu.be")) {
    let arr = uri.split('/')
    src = arr[arr.length-1]
  }

  if (uri.includes("youtube.com/watch")) {
    uri=uri+'&'
    let u1 = uri.substr(uri.lastIndexOf('v=')+2)
    src = u1.substr(0,u1.indexOf('&'))
  }

  return (
    <p class="iframe-container">
      <iframe title={text} src={"https://www.youtube.com/embed/" + src} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>
    </p>
  )
}

const options = {
  renderMark: {
    [MARKS.CODE]: text => <MarksCode text={text} />
  },
  renderNode: {
    [BLOCKS.QUOTE]: (node, children) => {
      return <MarksQuote text={children} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (!node.data.target.fields) {
        return <div>[... wrong embedded entry, try toclean cache ...]</div> ;
      } else {  
        const { title, slug, cover } = node.data.target.fields;
        return <EmbeddedEntry title={title} slug={slug} cover={cover} />;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (!node.data.target.fields) {
        return <div>[... wrong embedded asset, try toclean cache ...]</div> ;
      } else {  
        const { title, description, file } = node.data.target.fields;
        return <EmbeddedAsset title={title} file={file} description={description} />;
      }
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      const { id,  linkType } = node.data.target.sys;
      return <CustomComponent id={id} type={"inline"} linkType={linkType} />;
    },
    [INLINES.HYPERLINK]: node => {
      const { uri} = node.data;
      const text = node.content[0].value
      const data = node.content[0].data
      
      let isVideoLink = false
      if (uri.includes("player.vimeo.com/video") || uri.includes("vimeo.com")) {
        isVideoLink = true
        return <InlineVimeo uri={uri} text={text} />
      }
      if (uri.includes("youtube.com/watch") || uri.includes("youtu.be")) {
        isVideoLink = true
        return <InlineYoutube uri={uri} text={text} />
      }
      if (!isVideoLink) {
        //this is to rendere external link as like embedded, but the info must to be previously fetched
        if (text.indexOf(">") === 0) 
              { return <EmbeddedExternalEntry uri={uri} text={text.substr(1)} data={data} /> }
        else  {return <InlineLink uri={uri} text={text} />}
      }
    }
  }
}

const renderText = (text,  maxChar = 0) => {
  const allChar = documentToReactComponents(text,options)
  if (maxChar !== 0 && allChar.length > 0) {
    return allChar.substr(0,maxChar)
  } else {
    return allChar
  }
}

const RichText = ({text, maxChar = 0}) => {
  
  return (
    <span className="">
     {renderText(text, maxChar) }
    </span>
  )
}


export default RichText