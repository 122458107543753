import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import {Link} from "gatsby"

const SidebarRecentPosts = () => {

  const data = useStaticQuery(graphql`
    query sidebarPosts {
      featured: allContentfulSimpleLife(sort: {fields: date, order: DESC}, filter: {date: {gt: "2000-01-00T00:00+02:00"}, featured: {eq: true}}, limit: 4, skip: 1) {
        edges {
          node {
            comment
            date
            featured
            slug
            title
            shortDescription {
              json
            }
            content {
              json
            }
            cover {
              fluid(quality: 90) {
                aspectRatio
                sizes
                src
                srcSet
              }
            }
          }
          next {
            slug
            title
          }
          previous {
            title
            slug
          }
        }
        totalCount
      }
    }
  `)

  return (
    <div>
      <div className="sidebar-widget">
        <h2 className="widget-title">FOCUS</h2>
        {data.featured.edges.map (({node}) => (
            <div className="widget-recent-post" key={node.slug}>
              <Link to={'/'+node.slug} className="article-read-more">
                <h3 className="widget-recent-post-title">{node.title}</h3>
              </Link>
              
              <img
                src={node.cover.fluid.src}
                alt="two dumplings on a wood plate with chopsticks"
                className="widget-image"
              />
              
            </div>
           ))
          }
        
      </div>
    </div>
  )};

export default SidebarRecentPosts;
